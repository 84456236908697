<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
        <v-row justify="center" class="py-5 mt-8">
            <v-col>
                 <h1 class="text-h4 font-weight-light text-center">You need a login link to continue</h1>
                 <!-- <p class="text-center mt-10">You need a login link to continue.</p> -->
                 <p class="text-center mt-10">If you arrived here while trying to log in, please go back and contact the site administrator.</p>
                 <!-- <h2 class="text-h5 font-weight-light mt-10">Login-as-a-service (LaaS)</h2>
                 <p>This feature is still in development, but is already usable. Please <a href="https://loginfront.com">contact us</a> with questions or comments.</p>
                 <h2 class="text-h5 font-weight-light mt-10">Zero-trust enterprise application firewall</h2>
                 <p>This feature is still in development, but is already usable. Please <a href="https://loginfront.com">contact us</a> with questions or comments.</p> -->
            </v-col>
        </v-row>
        <!-- <v-row justify="center" class="py-5">
            <v-col style="text-align: center" cols="12" sm="6">
                <template v-if="!isAuthenticatedReady">
                    <h1 class="text-h4 font-weight-light mt-15">Checking authentication status...</h1>
                </template>
                <template v-if="isAuthenticatedReady">
                    <p class="mt-15" v-if="isAuthenticated">
                        <router-link to="/dashboard">Continue to dashboard</router-link>
                    </p>
                    <p class="mt-15" v-if="!isAuthenticated">
                        <router-link to="/login">Login</router-link>
                    </p>
                </template>
            </v-col>
        </v-row> -->
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        // init() {
        //     if (this.isAuthenticated) {
        //         this.$router.push('/dashboard');
        //     } else {
        //         this.$router.push('/login');
        //     }
        // },
    },
    watch: {
        // isAuthenticatedReady(value, oldValue) {
        //     if (value && !oldValue) {
        //         this.init();
        //     }
        // },
    },
    mounted() {
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
    },
};
</script>
